import { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import StoryCard from "../components/storyCard";
import AudioPlayer from "../components/audioPlayer";
import CollectionCard from "../components/collectionCard";
import BenefitCard from "../components/benefitCard";
import Link from "next/link";
//import { getPlans } from "../services/subscription.service";
import Head from "next/head";
import DownloadBig from "../components/downloadBig";
import RegisterBig from "../components/registerBig";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import DownloadLeft from "../components/downloadLeft";

export default function Home({ success, collection, plans }) {
  //console.log(plans);
  const [audioUrl, setAudioUrl] = useState("");
  const [playing, setPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [benefits, setBenefits] = useState([
    { title: "ისწავლოს", subtitle: "სწორი  მეტყველება" },
    { title: "განავითაროს", subtitle: "მოსმენის უნარი" },
    { title: "გაიუმჯობესოს", subtitle: "მახსოვრობა" },
    { title: "გაიღრმაოს წარმოსახვის და", subtitle: "ფანტაზიის უნარი" },
    { title: "გაზარდოს", subtitle: "სიტყვათა მარაგი" },
    // { title: "დაიკმაყოფილოს", subtitle: "ცნობისმოყვარეობა" },
    { title: "ბავშვობიდან შეიყვაროს", subtitle: "წიგნის კითხვა" },
  ]);
  ///const [collection, setCollection] = useState([]);
  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const isSubscriptionEnabled = useFeatureFlagEnabled("subscription");
  const plan = plans[0];
  const togglePlay = (audioURL, trackId) => {
    setCurrentTrack(trackId);

    //console.log("audio", audioURL, audioUrl);
    let audio = document.getElementById("audio");
    if (playing) {
      if (audioURL === audioUrl) {
        audio.pause();
        setPlaying(!playing);
      } else {
        setAudioUrl(audioURL);
        audio.src = audioURL;
        audio.play();
        setPlaying(true);
      }
    } else {
      setAudioUrl(audioURL);
      audio.src = audioURL;
      audio.play();
      setPlaying(true);
    }
  };

  return (
    <main className="d-flex flex-column min-vh-100">
      <Head>
        <meta
          name="description"
          content="ქართული ხალხური, ძმები გრიმები, ანდერსენი, შარლ პერო"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://bulbuli.ge/"></meta>
        <meta property="og:title" content="ბულბული"></meta>
        <meta
          property="og:description"
          content="ქართული ხალხური, ძმები გრიმები, ანდერსენი, შარლ პერო"
        ></meta>
        <meta
          property="og:image"
          content="https://bulbuli.ge/images/bulbuli_social_preview.jpeg"
        ></meta>
      </Head>
      <section className="main-section py-5">
        <div className="container">
          <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
            <div className="image-wrapper ">
              <img
                src="./images/phone.webp"
                className="phone-img"
                alt="bulbuli app"
              />
            </div>
            <div className="hero-text-wrapper">
              <h1 className="hero-text">აუდიო ზღაპრები პატარებისთვის</h1>
              <p className="hero-subtext">
                გახმოვანებული და გათამაშებული <br />
                მსახიობების მიერ
              </p>

              <div className="download-wrapper">
                <DownloadLeft />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="category-section py-5">
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <div className="text-center">
                <h2 className="hero-text dark-text">
                  აუდიო ზღაპერების დიდი არჩევანი
                </h2>
                <p className="hero-subtext dark-text">
                  {collection.map((item, index) => (
                    <Link
                      href={"/zgaprebi/" + item.title.replace(/ /g, "-")}
                      key={index}
                    >
                      <a>
                        {item.title}
                        {index != collection.length - 1 && ", "}
                      </a>
                    </Link>
                  ))}
                  {/* ანდერსენი, შარლ პერო, ქართული ხალხური, ძმები გრიმები, მსოფლიო
                  კლასიკა */}
                </p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5 d-flex justify-content-center">
            {/* audio  */}
            {/* <AudioPlayer audioUrl={audioUrl} playing={playing} /> */}
            <audio
              //src={audioUrl}
              controls
              style={{ display: "none" }}
              id="audio"
            ></audio>
            <StoryCard
              className="big"
              imgURL="images/komble.webp"
              onClick={() => {
                togglePlay("/audio/komble_mixdown.mp3", 1);
              }}
              title="კომბლე"
              playing={playing}
              currentTrack={currentTrack}
              trackId={1}
            />
            <StoryCard
              className="big"
              imgURL="images/nacarqeqia.webp"
              onClick={() => {
                togglePlay("/audio/nacarqeqia_mixdown.mp3", 2);
              }}
              title="ნაცარქექია"
              playing={playing}
              currentTrack={currentTrack}
              trackId={2}
            />
            <StoryCard
              className="big"
              imgURL="images/mZinare_mzeTunaxavi.webp"
              onClick={() => {
                togglePlay("/audio/mZinare_mzeTunaxavi_mixdown.mp3", 3);
              }}
              title="მძინარე მზეთუნახავი"
              playing={playing}
              currentTrack={currentTrack}
              trackId={3}
            />
          </div>
          {/* <div className="row mt-5">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <div className="text-center">
                <p className="hero-subtext">
                  ანდერსენის ზღაპრები, შარლ პერო, ქართული ხალხური, ძმები
                  გრიმები, მსოფლიო კლასიკა
                </p>
              </div>
            </div>
          </div> */}
          <div
            className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 d-flex flex-row justify-content-center align-items-center align-content-center"
            //style={{ backgroundColor: "white",}}
          >
            {collection.map((item, index) => {
              return (
                <CollectionCard
                  key={index}
                  imgURL={item.imageURL}
                  title={item.title}
                  stories={item.stories}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="pricing-section py-5">
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <div className="text-center">
                <h2 className="hero-text dark-text">
                  დაიწყეთ ზღაპრების მოსმენა
                </h2>
                <p className="hero-subtext dark-text">
                  დარეგისტრირდით და შეიძინეთ წვდომა ზღაპრების სრულ ბაზაზე
                </p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-3 g-4 pt-5 d-flex justify-content-center">
            <div className="pricing-cnt ">
              <div className="d-flex flex-column mb-2">
                <div className="price-badge">
                  <div>
                    <span className="h1 dark-text">
                      {plan.price.amount + "" + plan.price.currencySign + " / "}
                    </span>

                    <span className="h1 h1-slim dark-text">
                      {plan.planTtitle}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <span className="trial normal-text">
                    მოუსმინეთ 7 დღე უფასოდ
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ul className="benefits normal-text dark-text list-unstyled ml-5">
                  <li>
                    <i
                      className="fa fa-check mr-2"
                      style={{ color: "#06d6a0" }}
                    ></i>
                    წვდომა ზღაპრების სრულ ბაზაზე
                  </li>
                  <li>
                    <i
                      className="fa fa-check mr-2"
                      style={{ color: "#06d6a0" }}
                    ></i>
                    ყოველკვირეულად განახლებადი ზღაპრები
                  </li>
                  <li>
                    <i
                      className="fa fa-check mr-2"
                      style={{ color: "#06d6a0" }}
                    ></i>
                    გახმოვანებული მსახიობების მიერ
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benefit-section py-5">
        <div className="benefit-cnt">
          <div className="row">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <div className="text-center">
                <h2 className="hero-text dark-text">რატომ ბულბული?</h2>
                <p className="hero-subtext dark-text">
                  ბულბულის აუდიო ზღაპრები დაეხმარება თქვენს პატარას:
                </p>
              </div>
            </div>
          </div>
          <div
            className="row row-cols-1 row-cols-lg-3 g-4 py-5 d-flex justify-content-center flex-nowrap"
            style={{ overflow: "scroll" }}
          >
            {benefits.map((item, index) => {
              return (
                <BenefitCard
                  key={"bn_" + index}
                  index={"bn_" + index}
                  title={item.title}
                  subtitle={item.subtitle}
                  degree={randomInteger(-10, 10)}
                />
              );
            })}
          </div>
          {/* <div className="row mt-5">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <div className="text-center">
                <p className="hero-subtext">
                  ანდერსენის ზღაპრები, შარლ პერო, ქართული ხალხური, ძმები
                  გრიმები, მსოფლიო კლასიკა
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className="download-section py-5">
        {isSubscriptionEnabled ? <RegisterBig /> : <DownloadBig />}
      </section>
      {/* <section className="plan-section">
        <div className="container">
          <div className="row">
            <div className="col d-flex flex-column justify-content-start align-items-center align-content-center">
              <h1 className="hero-text">აუდიო ზღაპრები პატარებისთვის</h1>
              <p className="hero-subtext">
                100-ზე მეტი ზღაპარი მოთხრობილი პროფესიონალი მსახიობების მიერ
              </p>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center g-4 py-5">
            {plans.map((item, index) => {
              return (
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3">
                      <h4 className="my-0 fw-normal">Free</h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">
                        {item.price.amount}{item.price.currencySign}
                        <small className="text-muted fw-light">
                          /{item.planTtitle}
                        </small>
                      </h1>
                      
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section> */}
    </main>
  );
}

export const getStaticProps = async () => {
  //const plans = await getPlans();
  //console.log(plans);
  const plansResult = await fetch(
    `${process.env.APP_URL}/api/subscription/plans`,
    {
      method: "GET",
    }
  );
  const collectionResult = await fetch(
    `${process.env.APP_URL}/api/story/collectionsPublic`,
    {
      method: "GET",
    }
  );
  //&& plansResult.status === 200
  if (collectionResult.status === 200) {
    return {
      props: {
        success: true,
        collection: await collectionResult.json(),
        plans: await plansResult.json(),
      },
    };
  } else {
    //console.log(result);
    return {
      props: {
        success: false,
        collection: [],
        error: { message: "მოხდა შეცდომა" },
      },
    };
  }
};
