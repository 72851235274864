const DownloadBig = () => {
  return (
    <>
      <div className="mobile">
        <div className="row">
          <div className="w-100 d-flex justify-content-start flex-column align-items-center align-content-center text-center">
            <a
              href="https://link.bulbuli.ge/web"
              target="_blank"
              className="btn btn-download"
            >
              გადმოწერეთ აპლიკაცია
            </a>
          </div>
        </div>
      </div>
      <div className="desktop">
        <div className="row">
          <div className="col d-flex py-5 flex-column justify-content-start align-items-center align-content-center">
            <h2 className="hero-text" style={{ color: "#373985" }}>
              გადმოწერეთ აპლიკაცია
            </h2>
            <div className="d-flex flex-direction-row">
              <a
                href="https://play.google.com/store/apps/details?id=ge.istoriebi_new.mchost"
                target="_blank"
                style={{ marginRight: 10 }}
              >
                <img
                  src="/images/play_store.png"
                  className="img-download-big"
                  alt="play store"
                />
              </a>

              <a
                href="https://apps.apple.com/us/app/bulbuli/id1540480146"
                target="_blank"
              >
                <img
                  src="/images/app_store.png"
                  className="img-download-big"
                  alt="app store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadBig;
